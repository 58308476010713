<template>
  <div>
    <p v-if="fetching"><Loading /></p>
    <Form ref="form">
        <img class="s-rounded-lg s-w-full" :src="coverImage">
      <h2 class="s-text-xl s-text-gray-darker s-font-semibold s-mb-2">{{ product.title }}</h2>
      <div>
        <dl class="s-flex">
          <dt>商品單價：</dt>
          <dd>{{ spec.price }} 元</dd>
        </dl>
        <dl class="s-flex">
          <dt>結單時間：</dt>
          <dd>{{ endAt }}</dd>
        </dl>
      </div>
      <div class="s-product-form">
        <Counter
          class="s-mb-4"
          label="下單數量"
          name="counter"
          :min="min"
          :max="max"
          :step="basis"
          :help="`下單數量最多 ${max} 個，最少 ${min} 個`"
          help-class="s-text-danger"
          v-model="quantity"
        />
        <div class="s-flex s-items-center">
          <dl class="s-flex-shrink-0 s-flex s-items-center s-mr-2">
            <dt class="s-mr-2">金額</dt>
            <dd class="s-text-xl s-text-secondary s-price">$ {{ price }}</dd>
          </dl>
          <button class="s-btn s-btn-primary" type="submit">
            確定下單
          </button>
        </div>
      </div>
    </Form>
  </div>
</template>

<script>
  import moment from 'moment'
  import { mapActions } from 'vuex'
  import Loading from '@/components/Page/Liff/Shared/Loading'
  import Form from '@/components/Page/Liff/Form/Form'
  import Counter from '@/components/Page/Liff/Form/Counter'
  import withFetcher from '@/mixins/liff/withFetcher'

  export default {
    mixins: [withFetcher],
    components: { Loading, Form, Counter },
    data: () => ({
      quantity: 1,
      order: [{
        items: [{
          product: {}
        }]
      }],
    }),
    computed: {
      coverImage () {
        const images = this.order.items[0]?.product?.images || [{}]
        return images[0].pic_url
      },
      product () {
        return this.order.items[0]
      },
      spec () {
        return this.order.items[0]
      },
      min () {
        return 1
      },
      max () {
        return 10
      },
      basis () {
        return 1
      },
      price () {
        return this.spec.price * this.quantity
      },
      endAt () {
        return moment(new Date()).format("YYYY/MM/DD HH:mm");
      },
    },
    methods: {
      ...mapActions({
        show: 'liffOrder/showOrder',
      }),
      async fetch () {
        this.order = []
        const  data  = await this.show(
          { id: this.$route.params.id }
          )
        this.order = data
      },
    }
  }
</script>

<style scoped>
  .s-product-form {
    position: sticky;
    bottom: 0;
    background-color: var(--s-white);
    padding: 0.5rem 0;
    margin-bottom: -1.25rem;
  }

  .s-price {
    min-width: 5rem;
  }
</style>
